import React from "react";
import { ImQuotesLeft } from "react-icons/im";

const NewsLetterSubscribtion = () => {
  return (
    <section className="max-w-[1200px] mx-auto relative -top-80">
      {/* top part */}
      <div className="bg-white flex flex-col sm:flex-row items-center">
        <div className="w-1/2">
          <img
            src="/images/pm6.jpg"
            alt="doctor talking with patients"
            className="w-full"
          />
        </div>
        <div className="w-full px-2 sm:pl-8 sm:pr-5 flex flex-col justify-center md:w-1/2 md:pl-14 ">
          <i>
            <ImQuotesLeft size="40px" fill="rgb(10, 124, 139)" />
          </i>
          <p className="py-5 lg:py-10 text-base text-gray-600">
          Schließt eine Reha-Maßnahme unmittelbar an Ihren Krankenhausaufenthalt an, ist es oft schwierig, eigenständig das Ziel zu erreichen. Denn nicht immer sind Patienten gesundheitlich dazu in der Lage mit dem privaten Pkw oder öffentlichen Verkehrsmitteln anzureisen.
          </p>
          <h1 className="text-xl font-bold text-primary md:pb-5">
            Premium Mobility
          </h1>
          <p className="text-sm text-gray-600">Düsseldorf</p>
        </div>
      </div>
    </section>
  );
};

export default NewsLetterSubscribtion;
