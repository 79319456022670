import React from "react";

const Certificates = () => {
  return (
    <section className="w-full bg-secondary pb-56 relative -top-[164px]">
      <div className="max-w-[1200px] mx-auto px-2">
        <h1 className="text-3xl font-bold text-primary text-center pt-48">
          Sicher mobil mit der PM Krankenbeförderung Düsseldorf
        </h1>
      </div>
    </section>
  );
};

export default Certificates;
