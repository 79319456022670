import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper";
import TestimonialCard from "../../components/cards/TestimonialCard";

const Testimonial = () => {
  const testimonials = [
    {
      id: 1,
      experience:
        "Wir sind jetzt das dritte Mal mit Premium Mobility gefahren und sind begeistert. Sehr nette angenehme Fahrer sowie die Zentrale wenn man anruft. Einer der besten die wir bisher hatten. Weiter so",
      img: "https://a6e8z9v6.stackpathcdn.com/mediz/hospital/wp-content/uploads/sites/2/2019/12/testimonial-4-150x150.jpg",
      name: "",
      profession: "",
    },
    {
      id: 2,
      experience:
        "Erstklassiger Krankentransport, der immer für uns da war. Die Erreichbarkeit ist wirklich erklassig, hier ist man total flexibel eingestellt. Die Fahrer sind immer pünktlich, haben immer offene Ohren und total freundlich.",
      img: "https://a6e8z9v6.stackpathcdn.com/mediz/hospital/wp-content/uploads/sites/2/2019/12/testimonial-2-150x150.jpg",
      name: "",
      profession: "",
    },
    {
      id: 3,
      experience:
        "Ich haben gute Erfahrungen mit dem Unternehmen gemacht. Die Fahrer sind sehr Freundlich und zuvorkommend. Hier wird auf Pünktlichkeit und guter Umgang viel Wert gelegt. Ich kann dieses Unternehmen definitiv weiterempfehlen.",
      img: "https://a6e8z9v6.stackpathcdn.com/mediz/hospital/wp-content/uploads/sites/2/2019/12/testimonial-4-150x150.jpg",
      name: "",
      profession: "",
    },
    {
      id: 4,
      experience:
        "Fahrer war überpünktlich und freundlich. Hilfsbereitschaft und Flexibilität wird großgeschrieben. Es hat alles wunderbar geklappt. Vielen Dank dafür.",
      img: "https://a6e8z9v6.stackpathcdn.com/mediz/hospital/wp-content/uploads/sites/2/2019/12/testimonial-1-150x150.jpg",
      name: "",
      profession: "",
    },
  ];

  return (
    <section className="max-w-[1200px] mx-auto px-2 relative -top-36">
      <h1 className="text-3xl font-bold text-primary text-center">
        Erfahrungen unserer Kunden
      </h1>
      <Swiper
        slidesPerView={1}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
      >
        {testimonials.map((testimonial) => (
          <SwiperSlide key={testimonial.id}>
            <TestimonialCard
              experience={testimonial.experience}
              img={testimonial.img}
              name={testimonial.name}
              profession={testimonial.profession}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Testimonial;
