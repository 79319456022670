import React from "react";
import NewsCard from "../../components/cards/NewsCard";
import QuickLinkCard from "../../components/cards/QuickLinkCard";
import news from "../../data/News";
import links from "../../data/QuickLink";

const RecentNews = () => {
  return (
    <section className="max-w-[1200px] mx-auto px-2 relative -top-64 flex flex-col  md:flex-row">
    </section>
  );
};

export default RecentNews;
