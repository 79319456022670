import React from "react";
import WeOfferCard from "./cards/WeOfferCard";

const Weoffer = () => {
  const offers = [
    {
      id: 1,
      background: "bg-primary",
      img: "https://a6e8z9v6.stackpathcdn.com/mediz/hospital/wp-content/uploads/sites/2/2019/12/col-feature-1.png",
      title: "Dialysefahrten – unsere Aufmerksamkeit gehört Ihnen",

      description:
        "Für viele Menschen ist die regelmäßige Fahrt zur Dialyse Alltag. Als professioneller Krankenfahrdienst in Düsseldorf unterstützen wir Sie dabei, diesen Teil des Alltags so unkompliziert wie möglich zu bewältigen.",
    },
    {
      id: 2,
      background:
        "bg-primary",
      img: "https://a6e8z9v6.stackpathcdn.com/mediz/hospital/wp-content/uploads/sites/2/2019/12/col-feature-2.png",
      title: "Krankenfahrdienst aus Düsseldorf – immer für Sie da",
      description:
        "Wir sehen uns als zuverlässiger Partner für alle Menschen, die in regelmäßigen Intervallen zur Dialyse müssen. Dabei unterscheiden wir nicht zwischen Einzelfahrten oder dauerhaften Lösungen.",
    },
    {
      id: 3,
      background: "bg-primaryDark",
      img: "https://a6e8z9v6.stackpathcdn.com/mediz/hospital/wp-content/uploads/sites/2/2019/12/col-feature-3.png",
      title: "Zuverlässig zu ihren Terminen",
      description:
        "Wir sehen uns als zuverlässiger Partner für alle Menschen, die in regelmäßigen Intervallen zur Dialyse müssen. Dabei unterscheiden wir nicht zwischen Einzelfahrten oder dauerhaften Lösungen.",
    },
  ];
  return (
    <section className="relative -top-56 md:flex">
      {offers.map((offer) => (
        <WeOfferCard
          key={offer.id}
          title={offer.title}
          description={offer.description}
          background={offer.background}
          img={offer.img}
        />
      ))}
    </section>
  );
};

export default Weoffer;
