import React from "react";
import values from "../../data/OurValue";
import leaders from "../../data/OurLeader";
import ValuesCard from "../../components/cards/ValuesCard";
import Heading from "../../components/ui/Heading";
import AboutMedCare from "./AboutMedCare";
import ChairmanMessage from "./ChairmanMessage";
import LeadersCard from "../../components/cards/LeadersCard";
import Footer from "../../layouts/Footer";
import ScrollToTop from "../../components/ui/ScrollToTop";

const AboutUs = () => {
  return (
    <>
      <div
        style={{
          backgroundImage: `linear-gradient(to top,rgba(10, 124, 139,0.4) 70%,rgba(10, 124, 139,0.5) 100%), url(/images/pm5.jpg)`,
        }}
        className="mt-10 w-full bg-cover bg-no-repeat bg-center h-96 min-h-max saturate-150 xl:h-[500px]"
      ></div>
      <div className="bg-primary">
        <h1 className="max-w-[1200px] mx-auto px-2 py-5 text-3xl font-semibold text-white">
        Krankenbeförderung Premium Mobility in Düsseldorf
        </h1>
      </div>
      <div className="max-w-[1200px] mx-auto px-2">
        <AboutMedCare />
      </div>
      <Footer />
      <ScrollToTop />
    </>
  );
};

export default AboutUs;
