import React, { useState } from "react";
import VideoInfoBox from "./VideoInfoBox";
import VideoCard from "../../components/cards/VideoCard";

const TabGallery = () => {
  const [activeTab, setActiveTab] = useState(1);

  return;
};

export default TabGallery;
