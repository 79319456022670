const Services = [
  {
    id: 1,
    img: "/images/heart.png",
    title: "Sicherheit",
    text: "Rollstuhlfahrten gut gesichert und komfortabel",
  },
  {
    id: 2,
    img: "/images/heart.png",
    title: "Rehafahrten",
    text: "Rehafahrten zu nahe gelegenen oder weit entfernten Einrichtungen",
  },
  {
    id: 3,
    img: "/images/heart.png",
    title: "Dialysefahrten",
    text: "Dialysefahrten als Einzelfahrt oder nach fest vereinbartem Terminplan",
  },
  {
    id: 4,
    img: "/images/heart.png",
    title: "Fernverlegungen",
    text: "Fernverlegungen in weit entfernte Kliniken",
  },
  {
    id: 5,
    img: "/images/heart.png",
    title: "Hospitaltransfer",
    text: "Hospitaltransfer innerhalb Düsseldorfs und Umgebung",
  },
  {
    id: 6,
    img: "/images/heart.png",
    title: "Chemotherapie-und Bestrahlungsfahrten",
    text: "Fahrten zur ambulant durchgeführten onkologischen Strahlen- und Chemotherapien",
  },
];

export default Services;
