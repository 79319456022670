const Expertises = [
  {
    id: 1,
    img: "./images/immune.png",
    title: "Adresse",
    text: "Linienstraße 46, 40227 Düsseldorf",
  },
  {
    id: 2,
    img: "./images/anti-malarial.png",
    title: "Telefon",
    text: "0211 / 822 68 244",
  },
  {
    id: 3,
    img: "./images/nutrition.png",
    title: "E-Mail",
    text: "info@pm-duesseldorf.de",
  },
];

export default Expertises;
