import React from "react";
import { Link } from "react-router-dom";
import {
  AiOutlineMail,
  AiOutlineClockCircle,
  AiFillSkype,
} from "react-icons/ai";
import { BsTelephone, BsTwitter } from "react-icons/bs";
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import FooterList from "../components/FooterList";

const Footer = () => {
  const aboutUsLists = [
    {
      id: 1,
      text: "Our Mission  & Values",
    },
    {
      id: 2,
      text: "Leadership",
    },
    {
      id: 3,
      text: "Transformation",
    },
    {
      id: 4,
      text: "Awards",
    },
    {
      id: 5,
      text: "Diversity is Our Speciality",
    },
    {
      id: 6,
      text: "Publications & Reports",
    },
    {
      id: 7,
      text: "Policies & Procedures",
    },
  ];

  const ourServicesLists = [
    {
      id: 1,
      text: "Lung Disease",
    },
    {
      id: 2,
      text: "Orthopaedic",
    },
    {
      id: 3,
      text: "Pharmacy",
    },
    {
      id: 4,
      text: "Our Mission & Values",
    },
    {
      id: 5,
      text: "Sport Injury",
    },
    {
      id: 6,
      text: "Heart",
    },
    {
      id: 7,
      text: "Dental Service",
    },
  ];

  return (
    <footer className="w-full bg-primary mt-6 py-14">
      {/* all details */}
      <div className="max-w-[1200px] mx-auto px-2 flex justify-start gap-x-20 gap-y-10 items-start flex-wrap md:justify-evenly md:gap-x-10 lg:gap-0 lg:justify-start lg:flex-nowrap">
        {/* first col */}
        <div className="w-full sm:w-1/2 md:w-1/4">
          <h2 className="mb-5 text-3xl text-white font-semibold tracking-wide leading-8">
            Premium Mobility
          </h2>
          <span className="text-base text-white">
            Linienstraße 46 <br />
            40227 Düsseldorf <br />
          </span>
          <span className="pt-8 text-base text-white flex flex-wrap items-center gap-x-3">
            <BsTelephone />
            0211 / 822 68 244
          </span>
          <span className="mt-2 text-base text-white flex flex-wrap items-center gap-x-3">
            <AiOutlineMail />
            info@pm-duesseldorf.de
          </span>
        </div>
      </div>

      {/* copyright section */}
      <div className="max-w-[1200px] mx-auto mt-14 px-2 flex items-center gap-x-3 gap-y-5 justify-center flex-wrap sm:justify-between">
        <div className="uppercase text-white text-center">
          Copyright 2023 Premium Mobility, All rights Reserved
        </div>
      </div>
    </footer>
  );
};

export default Footer;
