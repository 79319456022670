const doctors = [
  {
    id: 1,
    name: "Rollstuhlbeförderung",
    profession: "",
    img: "/images/our_doctors/wheelchair.jpg",
    bio: "https://demo.goodlayers.com/mediz/hospital/personnel/thomas-paul/",
  },
  {
    id: 2,
    name: "Tragestuhlbeförderung",
    profession: "",
    img: "/images/our_doctors/pm1.png",
    bio: "https://demo.goodlayers.com/mediz/hospital/personnel/david-james/",
  },
  {
    id: 4,
    name: "Sitzend",
    profession: "",
    img: "/images/our_doctors/pm3.jpg",
    bio: "https://demo.goodlayers.com/mediz/hospital/personnel/joshua-clark/",
  },
];

export default doctors;
