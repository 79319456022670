import React from "react";

const AboutMedCare = () => {
  return (
    <div className="pt-5 mb-7">
      <p className="py-3 leading-7 text-black">
      Unsere Krankenfahrten werden immer von zwei gut ausgebildeten Mitarbeitern begleitet. Jeder der beiden Mitarbeiter betreut Sie vom Einsteigen in das Fahrzeug bis zur Ankunft am Zielort mit Einfühlungsvermögen und gibt Ihnen die Unterstützung, die Sie benötigen. Dabei stehen Ihre persönlichen Bedürfnisse immer im Vordergrund. Ob Sie die Fahrt im Rollstuhl antreten, wir Sie liegend fahren oder Sie während der Fahrt bequem sitzen. Uns ist es wichtig, dass Sie sich vom Fahrtantritt bis zur Ankunft so wohl wie möglich fühlen. Bereiten Sie sich gedanklich entspannt auf Ihre Reha vor und überlassen Sie den Rest einfach uns.
      </p>
      <p className="py-3 leading-7 text-black">
        <span className="text-primary">Als erfahrener Dienstleister</span> für die Krankenbeförderung in Düsseldorf und Umgebung rechnen wir direkt mit Ihrer Krankenkasse ab. Für Sie bleibt nur der gesetzliche Eigenanteil in Höhe von 10 Prozent. Besitzen Sie einen von der Krankenkasse ausgestellten Befreiungsausweis, entfällt dieser Eigenanteil.
      </p>
      <p className="py-3 leading-7 text-black">
      Um Ihre Rehafahrt direkt mit der Krankenkasse abrechnen zu können, benötigen wir von Ihnen eine ärztliche Verordnung für die Krankenbeförderung. Diese stellt Ihnen Ihr behandelnder Arzt oder das Krankenhaus aus. Gerne unterstützen wir Sie bei den erforderlichen Formalitäten vor Ort.
      </p>
    </div>
  );
};

export default AboutMedCare;
