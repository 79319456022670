import React, { useEffect, useRef, useState } from "react";
import ServiceCard from "./ServiceCard";
import { BsSearch } from "react-icons/bs";
import ScrollToTop from "../../components/ui/ScrollToTop";
import Footer from "../../layouts/Footer";
import ArrayFilter from "../../components/ArrayFilter";
import diseases_a from "../../data/Diseases_A";
import diseases_b from "../../data/Diseases_B";
import diseases_c from "../../data/Diseases_C";
import diseases_d from "../../data/Diseases_D";
import diseases_e from "../../data/Diseases_E";

const alphabets = [
  {
    id: 1,
    letter: "a",
    diseases: diseases_a,
  },
  {
    id: 2,
    letter: "b",
    diseases: diseases_b,
  },
  {
    id: 3,
    letter: "c",
    diseases: diseases_c,
  },
  {
    id: 4,
    letter: "d",
    diseases: diseases_d,
  },
  {
    id: 5,
    letter: "e",
    diseases: diseases_e,
  },
  // {
  //   id: 6,
  //   letter: "f",
  // },
  // {
  //   id: 7,
  //   letter: "g",
  // },
  // {
  //   id: 8,
  //   letter: "h",
  // },
  // {
  //   id: 9,
  //   letter: "i",
  // },
  // {
  //   id: 10,
  //   letter: "j",
  // },
  // {
  //   id: 11,
  //   letter: "k",
  // },
  // {
  //   id: 12,
  //   letter: "l",
  // },
  // {
  //   id: 13,
  //   letter: "m",
  // },
  // {
  //   id: 14,
  //   letter: "n",
  // },
  // {
  //   id: 15,
  //   letter: "o",
  // },
  // {
  //   id: 16,
  //   letter: "p",
  // },
  // {
  //   id: 17,
  //   letter: "q",
  // },
  // {
  //   id: 18,
  //   letter: "r",
  // },
  // {
  //   id: 19,
  //   letter: "s",
  // },
  // {
  //   id: 20,
  //   letter: "t",
  // },
  // {
  //   id: 21,
  //   letter: "u",
  // },
  // {
  //   id: 22,
  //   letter: "v",
  // },
  // {
  //   id: 23,
  //   letter: "w",
  // },
  // {
  //   id: 24,
  //   letter: "x",
  // },
  // {
  //   id: 25,
  //   letter: "y",
  // },
  // {
  //   id: 26,
  //   letter: "z",
  // },
];

const Services = () => {
  const [enterDiseaseName, setEnterDiseaseName] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  let listOfDiseases = useRef(null);

  useEffect(() => {
    let length = listOfDiseases.current.children.length;
    if (length === 0) {
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
  }, []);
  return (
    <>
      {/* bgimage */}
      <section>
        <div
          style={{
            backgroundImage: `linear-gradient(to bottom,rgba(10, 124, 139,0.3) 70%,rgba(10, 124, 139,0.5) 100%), url(/images/services/pm8.png)`,
          }}
          className="mt-10 w-full bg-cover bg-no-repeat bg-center min-h-max saturate-150 h-[600px]"
        >
          <div className="max-w-[1200px] mx-auto h-full flex items-center justify-end">
            <div className="hidden lg:block py-8 px-6 w-96 float-right bg-white rounded-xl">
              <h1 className="text-primary text-4xl font-semibold leading-10">
              In jeder Situation an Ihrer Seite
              </h1>
              <p className="py-8 text-base font-poppins">
              Die Mitarbeiter der Krankenbeförderung Düsseldorf sind in jeder Situation an Ihrer Seite. Dank der modernen Technik in unseren Fahrzeugen sind wir in der Lage, Sie in jeder Position sicher und komfortabel zu fahren. Rollstuhlfahrer besteigen unsere Fahrzeuge mit Unterstützung unserer Mitarbeiter über eine komfortable Rampe. Im Fahrzeuginneren sorgen speziell auf Rollstühle ausgelegte Sicherheits- und Rückhaltesysteme für eine sichere und bequeme Fahrt.
              </p>

            </div>
          </div>
        </div>
      </section>

      <ServiceCard />

      <section className="bg-primary">
      </section>

      {/* filter disease from list */}
      <section className="max-w-[1200px] mx-auto px-2 mt-10">
       

        {/* list of all diseases */}

  

        {/* list of diseases */}
        <ul ref={listOfDiseases}>
        </ul>
      </section>

      <Footer />
      <ScrollToTop />
    </>
  );
};

export default Services;
