import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";

const Form = () => {
  const form = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    if (isSubmitting) return;

    setIsSubmitting(true);

    emailjs
      .sendForm(
        "service_ql4lv6a",
        "template_6iz9909",
        form.current,
        "h0gZuoAG6YyE7RBC3"
      )
      .then(
        (result) => {
          console.log(result.text);
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
        }
      )
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <form
      ref={form}
      onSubmit={sendEmail}
      className="w-full mx-auto mt-10 md:m-0 md:w-3/4 lg:w-1/2"
    >
      <div className="before:content-[''] before:bg-formImg before:bg-cover before:bg-no-repeat before:bg-center before:saturate-150 before:brightness-50 before:absolute before:top-0 before:bottom-0 before:left-0 before:right-0 relative w-full h-full">
        <div className="max-w-[600px] mx-auto pt-36 px-4 pb-10 md:m-0 md:pr-0 xl:pl-16 text-white relative">
          <h1 className="text-2xl lg:text-3xl font-bold">
            Fahrt buchen
          </h1>
          <p className="my-6 text-base text-gray-400">
            Wir stehen jederzeit für Sie zur Verfügung
            <br />
            <strong className="text-white font-bold">Notfall? </strong>Telefon :
            0211 / 822 68 244{" "}
          </p>
          <div className="pr-2 mx-auto md:m-0">
            <div className="flex gap-x-4">
              <input
                type="text"
                name="appointment_name"
                id="appointment_name"
                placeholder="Name&#42;"
                aria-label="Your Full Name"
                className="w-1/2 text-black placeholder:text-gray-600 px-2 py-3 rounded text-base font-semibold focus:outline-2 focus:outline-primaryDark outline-none focus:ring-2 focus:ring-primaryDark"
              />
              <input
                type="text"
                name="appointment_number"
                id="appointment_number"
                placeholder="Telefon&#42;"
                aria-label="Your Phone Number"
                className="w-1/2 text-black placeholder:text-gray-600 px-2 py-3 rounded text-base font-semibold focus:outline-2 focus:outline-primaryDark outline-none focus:ring-2 focus:ring-primaryDark"
              />
            </div>
            <div className="mt-5 flex gap-x-4">
              <input
                type="email"
                name="appointment_email"
                id="appointment_email"
                placeholder="E-Mail&#42;"
                aria-label="Your email address"
                className="w-1/2 text-black placeholder:text-gray-600 px-2 py-3 rounded text-base font-semibold focus:outline-2 focus:outline-primaryDark outline-none focus:ring-2 focus:ring-primaryDark"
              />
            </div>
            <div className="mt-5 flex gap-x-4">
              <input
                type="string"
                name="appointment_date"
                id="appointment_date"
                placeholder="Datum"
                aria-label="An Appointment Date"
                className="w-1/2 text-black placeholder:text-gray-600 px-2 py-3 rounded text-base font-semibold focus:outline-2 focus:outline-primaryDark outline-none focus:ring-2 focus:ring-primaryDark"
              />
              <input
                type="string"
                name="appointment_time"
                id="appointment_time"
                placeholder="Uhrzeit"
                aria-label="An Appointment Time"
                className="w-1/2 text-black placeholder:text-gray-600 px-2 py-3 rounded text-base font-semibold focus:outline-2 focus:outline-primaryDark outline-none focus:ring-2 focus:ring-primaryDark"
              />
            </div>
            <textarea
              name="appointment_message"
              id="appointment_message"
              aria-label="Text Message during appointment booking"
              cols="30"
              rows="10"
              placeholder="Nachricht&#42;"
              className="mt-5 w-full text-black placeholder:text-gray-600 px-2 py-3 text-base font-semibold focus:outline-2 focus:outline-primaryDark outline-none focus:ring-2 focus:ring-primaryDark"
            ></textarea>
            <button
              type="submit"
              className="mt-4 py-3 bg-[rgba(20,193,185,0.56)] text-white text-xl font-normal w-full"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Sende..." : "Senden"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
