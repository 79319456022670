import React from "react";
import { FaHeartbeat } from "react-icons/fa";
import { AiOutlineClockCircle } from "react-icons/ai";
const EmergencyService = () => {
  return (
    <div className="max-w-[1200px] mx-auto px-2 py-10 flex items-start gap-x-5 flex-col gap-y-5 sm:gap-y-8 bg-gradient-to-r from-primaryDark to-primary md:flex-row lg:px-10 lg:relative -top-14 left-0 right-0">
      <div className="">
        <div className="flex items-center gap-x-3">
          <i>
            <FaHeartbeat size="25px" fill="white" />
          </i>
          <h1 className="text-white text-xl font-semibold">
          Die optimale Krankenbeförderung in jeder Situation
          </h1>
        </div>
        <p className="text-white text-base pt-3">
        Es gibt verschiedenste Gründe, warum eine Fahrt mit der Krankenbeförderung Düsseldorf erforderlich ist. In jedem Fall sind wir Ihr zuverlässiger und kompetenter Partner, der Sie pünktlich und sicher an Ihr Ziel bringt. Ob es die Verlegung in ein anderes Krankenhaus ist oder die Fahrt zur Dialyse. Wir sind in jeder Situation als Krankenfahrdienst mit Herz für Sie da.
        </p>
        <h1 className="text-white text-xl font-medium pt-5">
          Rufen Sie doch gerne an : 0211 / 822 68 244
        </h1>
      </div>
      {/* right part */}
      <div className="">
        <div className="flex items-center gap-x-3">
          <i>
            <AiOutlineClockCircle size="25px" fill="white" />
          </i>
          <h1 className="text-white text-xl font-semibold">Öffnungszeiten:</h1>
        </div>
        <p className="text-white text-base pt-3">
        Unsere hervorragend ausgebildeten Mitarbeiter sind in jeder Situation für Sie da. Wo auch immer Ihr Ziel liegt:
Unsere Mitarbeiter betreuen Sie von der Abfahrt bis zur Ankunft mit Herz und Menschlichkeit.
        </p>
        <div className="border-white border-b-[1px] text-white pb-7 py-4">
          <span className=" float-left">Montag - Samstag</span>
          <span className="float-right">06:00 - 22:00</span>
        </div>
        <div className="border-white border-b-[1px] text-white pb-7 pt-4">
          <span className=" float-left">Sonntag</span>
          <span className="float-right">06:00 - 20:00</span>
        </div>
      </div>
    </div>
  );
};

export default EmergencyService;
